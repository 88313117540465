import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, Select } from 'antd'
import { useQuery } from '@apollo/client'

import { FIND_MANY_ADMIN_TG_BOT } from '../gqls'
import TextArea from 'antd/es/input/TextArea'
import axios from 'axios'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const HOST_NAME = process.env.REACT_APP_HOST_NAME

const PushAdminTgBot = () => {
    const [form] = Form.useForm()
    const [comforts, setComforts] = useState([])

    useQuery(FIND_MANY_ADMIN_TG_BOT, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyAdminTgBot }) => {
            const uniqueArray = findManyAdminTgBot.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.companyId === item.companyId
                ))
            );
            const companiesData = uniqueArray.map((object, index) => ({
                value: object.company.id,
                label: object.company.name,
                key: index
            }))
            setComforts(companiesData)
        }
    })

    const handleSubmit = async (values) => {
        axios.post(HOST_NAME + 'tg-send-msg', {
            "companies": values.companies,
            "message": values.msg
        }).then(response => {

        })
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item name='companies' label='Компании'>
                    <Select
                        mode='multiple'
                        optionFilterProp='label'
                        placeholder='Выберите из списка'
                        options={comforts}
                    />
                </Form.Item>
                <Form.Item name='msg' label='Текст' rules={[rules.required]}>
                    <TextArea placeholder='Введите текст' />
                </Form.Item>
                <Button type='primary' htmlType='submit'>
                    Отправить
                </Button>
            </Form>
        </>
    )
}

export default PushAdminTgBot
