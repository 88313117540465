import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Column, Pie } from '@ant-design/plots';
import dayjs from 'dayjs'
import { forEach, groupBy } from 'lodash';
import { List, Table, Collapse } from 'antd'

import { FIND_UNIQUE_COMPANY } from '../gqls'

const { Panel } = Collapse;

const CompanyStatistics = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data: findUniqueCompanyData, loading: findUniqueCompanyLoading, error: findUniqueCompanyError } = useQuery(FIND_UNIQUE_COMPANY, {
        variables: {
            where: {
                id
            }
        },
        fetchPolicy: 'network-only',
    })

    if (findUniqueCompanyLoading || findUniqueCompanyError) return null
    const { findUniqueCompany } = findUniqueCompanyData

    const objects = findUniqueCompany.objects

    const dateList = [
        { year: '2024', currentMonth: 1 },
        { year: '2024', currentMonth: 2 },
        { year: '2024', currentMonth: 3 },
        { year: '2024', currentMonth: 4 },
        { year: '2024', currentMonth: 5 },
        { year: '2024', currentMonth: 6 },
        { year: '2024', currentMonth: 7 },
        { year: '2024', currentMonth: 8 },
        { year: '2024', currentMonth: 9 },
        { year: '2024', currentMonth: 10 },
        { year: '2024', currentMonth: 11 },
        { year: '2024', currentMonth: 12 },
        { year: '2025', currentMonth: 1 },
        { year: '2025', currentMonth: 2 }
    ];

    return <List>{
        objects.map(object => {
            var cancelText = [{ text: 'Не внесена предоплата', value: 'Не внесена предоплата', count: 0 },
            { text: 'Заявка не соответствует правилам бронирования', value: 'Заявка не соответствует правилам бронирования', count: 0 },
            { text: 'Телефон недоступен для звонка', value: 'Телефон недоступен для звонка', count: 0 },
            { text: 'Форс-мажор', value: 'Форс-мажор', count: 0 },
            { text: 'Отмена гостем', value: 'Отмена гостем', count: 0 },
            { text: 'Отменен без комментария', value: '', count: 0 }]
            var dataRequestCharts = []
            var dataSumCharts = []
            var dataFullSumCharts = []
            var fullSum = 0;
            var fullSumCloseDay = 0;
            dateList.forEach(date => {
                const endDayMonth = dayjs(date['currentMonth'] + '-01-' + date['year']).endOf("month").date()
                const startDate = dayjs(date['currentMonth'] + '-01-' + date['year'])
                const endDate = dayjs(date['currentMonth'] + '-' + endDayMonth + '-' + date['year'])
                const filteredRequests = object.requests.filter(request => (dayjs(request.reservedAt) >= startDate && dayjs(request.reservedAt) <= endDate))
                const filteredSchedule = object.schedules.filter(schedule => {
                    // Фильтруем даты в schedule.closes
                    const filteredDates = schedule.closes.filter(dateString => {
                        const date = dayjs(dateString, "DD.MM.YYYY"); // Преобразуем строку в объект dayjs
                        return date.isAfter(startDate.subtract(1, 'day')) && date.isBefore(endDate.add(1, 'day'));
                    });

                    // Оставляем schedule, если в filteredDates есть хотя бы одна дата
                    return filteredDates.length > 0;
                });
                var countRequestPayed = 0;
                var countRequestNoPayed = 0;
                var countRequestCancel = 0;
                var sum = 0;
                var sumCloseDay = 0;
                filteredRequests.forEach(request => {
                    switch (request.status) {
                        case '0':
                            countRequestNoPayed += 1
                            break;
                        case '1':
                            sum += request.discount ? request.discount : request.price
                            countRequestPayed += 1
                            break;
                        case '3':
                        case '4':
                            countRequestCancel += 1
                            const foundItem = cancelText.find(item => item.value === (request.cmntText == null ? '' : request.cmntText));
                            if (foundItem) {
                                foundItem.count += 1;
                            }
                            break;
                        default:
                            break;
                    }
                });
                filteredSchedule.forEach(schedule => {
                    sumCloseDay += schedule.price
                })
                dataSumCharts.push({
                    'value': sumCloseDay,
                    'type': 'Сумма по закрытым дням',
                    'id': date['currentMonth'] + '-' + date['year'],
                })
                dataSumCharts.push({
                    'value': sum,
                    'type': 'Сумма по заявкам',
                    'id': date['currentMonth'] + '-' + date['year'],
                })
                dataRequestCharts.push({
                    'value': countRequestCancel,
                    'type': 'Отмененные',
                    'id': date['currentMonth'] + '-' + date['year'],
                })
                dataRequestCharts.push({
                    'value': countRequestPayed,
                    'type': 'Оплаченные',
                    'id': date['currentMonth'] + '-' + date['year'],
                })
                dataRequestCharts.push({
                    'value': countRequestNoPayed,
                    'type': 'Не оплаченные',
                    'id': date['currentMonth'] + '-' + date['year'],
                })
                fullSum += sum
                fullSumCloseDay += sumCloseDay
            })
            dataFullSumCharts.push({
                'value': fullSumCloseDay,
                'type': 'Сумма по закрытым дням',
            })
            dataFullSumCharts.push({
                'value': fullSum,
                'type': 'Сумма по заявкам',
            })

            const annotationsRequest = [];
            forEach(groupBy(dataRequestCharts, 'id'), (values, k) => {
                const value = values.reduce((a, b) => a + b.value, 0);
                annotationsRequest.push({
                    type: 'text',
                    data: [k, value],
                    style: {
                        textAlign: 'center',
                        fontSize: 14,
                        fill: 'rgba(0,0,0,0.85)',
                    },
                    xField: 'id',
                    yField: 'value',
                    style: {
                        text: `${value}`,
                        textBaseline: 'bottom',
                        position: 'top',
                        textAlign: 'center',
                    },
                    tooltip: false,
                });
            });

            const annotationsSum = [];
            forEach(groupBy(dataSumCharts, 'id'), (values, k) => {
                const value = values.reduce((a, b) => a + b.value, 0);
                console.log(value)
                annotationsSum.push({
                    type: 'text',
                    data: [k, value],
                    style: {
                        textAlign: 'center',
                        fontSize: 14,
                        fill: 'rgba(0,0,0,0.85)',
                    },
                    xField: 'id',
                    yField: 'value',
                    style: {
                        text: `${value}`,
                        textBaseline: 'bottom',
                        position: 'top',
                        textAlign: 'center',
                    },
                    tooltip: false,
                });
            });

            const configSum = {
                data: dataSumCharts,
                xField: 'id',
                yField: 'value',
                stack: true,
                colorField: 'type',
                label: {
                    text: 'value',
                    textBaseline: 'bottom',
                    position: 'inside',
                },
                annotations: annotationsSum,
            };
            const configRequest = {
                data: dataRequestCharts,
                xField: 'id',
                yField: 'value',
                stack: true,
                colorField: 'type',
                label: {
                    text: 'value',
                    textBaseline: 'bottom',
                    position: 'inside',
                },
                annotations: annotationsRequest,
            };

            const configPie = {
                data: dataFullSumCharts,
                angleField: 'value',
                colorField: 'type',
                label: {
                    text: 'value',
                    style: {
                        fontWeight: 'bold',
                    },
                },
                legend: {
                    color: {
                        title: false,
                        position: 'right',
                        rowPadding: 5,
                    },
                },
            };

            const columns = [
                {
                    title: 'Причина отмены',
                    dataIndex: 'text',
                    key: 'text',
                },
                {
                    title: 'Количество',
                    dataIndex: 'count',
                    key: 'count',
                },
            ];
            return (
                objects.length > 1 ? (
                    <Collapse accordion>
                        <Panel header={object.name} key={object.id}>
                            <div key={object.id}>
                                <h1 key={object.id}>{object.name}</h1>
                                <h1>Статистика заявок</h1>
                                <Column {...configRequest} />
                                <Collapse accordion>
                                    <Panel header='Статистика отмен' key={object.id}>
                                        <div>
                                            <h1>Статистика отмен</h1>
                                            <Table
                                                dataSource={cancelText.filter((item) => item.text)} // Фильтруем пустые значения
                                                columns={columns}
                                                rowKey="text"
                                                pagination={false} // Отключаем пагинацию
                                            />
                                        </div>
                                    </Panel>
                                </Collapse>
                                <h1>Статистика денег</h1>
                                <Column {...configSum} />
                                <h1>Статистика денег за весь период</h1>
                                <Pie {...configPie} />
                            </div>
                        </Panel>
                    </Collapse>
                ) :
                    <div key={object.id}>
                        <h1 key={object.id}>{object.name}</h1>
                        <h1>Статистика заявок</h1>
                        <Column {...configRequest} />
                        <Collapse accordion>
                            <Panel header='Статистика отмен' key={object.id}>
                                <div>
                                    <h1>Статистика отмен</h1>
                                    <Table
                                        dataSource={cancelText.filter((item) => item.text)} // Фильтруем пустые значения
                                        columns={columns}
                                        rowKey="text"
                                        pagination={false} // Отключаем пагинацию
                                    />
                                </div>
                            </Panel>
                        </Collapse>
                        <h1>Статистика денег</h1>
                        <Column {...configSum} />
                        <h1>Статистика денег за весь период</h1>
                        <Pie {...configPie} />
                    </div>
            )
        })
    }
    </List>;

}

export default CompanyStatistics
