import React, { useState } from 'react'
import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Upload, Select, Switch, InputNumber } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { CREATE_ONE_OBJECT, FIND_MANY_COMPANY } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const tags = [
    {
        label: 'Мангальная зона',
        value: 'grill'
    },
    {
        label: 'Баня/парилка/сауна',
        value: 'bathhouse'
    },
    {
        label: 'Гараж',
        value: 'garage'
    },
    {
        label: 'Портативный гараж',
        value: 'portable garage'
    },
    {
        label: 'Детская площадка',
        value: 'playground'
    },
    {
        label: 'Беседка',
        value: 'gazebo'
    },
    {
        label: 'Бассейн',
        value: 'pool'
    },
    {
        label: 'Рядом с рекой',
        value: 'river'
    },
    {
        label: 'Трансфер',
        value: 'transfer'
    },
    {
        label: 'Можно с питомцами',
        value: 'pet'
    },
    {
        label: 'Кондиционер',
        value: 'conditioner'
    },
    {
        label: 'Wi-Fi',
        value: 'wifi'
    },
    {
        label: 'Санузел',
        value: 'bathroom'
    },
    {
        label: 'Плита для варки',
        value: 'stove'
    },
    {
        label: 'Холодильник',
        value: 'fridge'
    },
    {
        label: 'Микроволновая печь',
        value: 'microwave'
    },
    {
        label: 'Посуда/приборы',
        value: 'dishes'
    },
    {
        label: 'Питьевая вода',
        value: 'water'
    },
    {
        label: 'Караоке',
        value: 'karaoke'
    },
    {
        label: 'Телевизор',
        value: 'tv'
    },
    {
        label: 'Проектор',
        value: 'projector'
    },
    {
        label: 'Игровая зона в доме',
        value: 'gamezone'
    },
    {
        label: 'Постельное белье',
        value: 'bedding'
    },
    {
        label: 'Завтрак включен',
        value: 'breakfast'
    },
    {
        label: 'Отопление',
        value: 'heat'
    },
]

const places = [
    {
        label: 'г. Якутск',
        value: 'yakutsk'
    },
    {
        label: 'г. Якутск, Вилюйский тракт',
        value: 'vilyuyskiy'
    },
    {
        label: 'г. Якутск, Маганский тракт',
        value: 'maganskiy'
    },
    {
        label: 'г. Якутск, Намский тракт',
        value: 'namskiy'
    },
    {
        label: 'г. Якутск, Намцырский тракт',
        value: 'namtsyrskiy'
    },
    {
        label: 'г. Якутск, Покровский тракт',
        value: 'pokrovskiy'
    },
    {
        label: 'г. Якутск, Хатын-Юряхское шоссе',
        value: 'khatyn-yuryakhskoye'
    },
    {
        label: 'г. Якутск, Сергеляхское шоссе',
        value: 'sergelyakhskoye'
    },
    {
        label: 'г. Якутск, мкр. Кангалассы',
        value: 'khangalassy'
    },
    {
        label: 'г. Якутск, с. Пригородный',
        value: 'prigorodniy'
    },
    {
        label: 'г. Якутск, с. Старая Табага',
        value: 'tabaga'
    },
    {
        label: 'г. Якутск, с. Хатассы',
        value: 'khatassy'
    },
    {
        label: 'Амгинский район',
        value: 'amginskiy-rayon'
    },
    {
        label: 'Горный район',
        value: 'gorny-rayon'
    },
    {
        label: 'Мегино-Кангаласский район',
        value: 'megino-kangalasskiy-rayon'
    },
    {
        label: 'Намский район',
        value: 'namskiy-rayon'
    },
    {
        label: 'Нерюнгринский район',
        value: 'nerungrinskiy-rayon'
    },
    {
        label: 'Хангаласский район',
        value: 'khangalasskiy-rayon'
    }
]

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false
    }
}

const HOST_NAME = process.env.REACT_APP_HOST_NAME
const DraggableUploadListItem = ({ originNode, file }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: file.uid,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            {file.status === 'error' && isDragging ? originNode.props.children : originNode}
        </div>
    )
}

const AddObject = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()

    const [images, setImages] = useState([])
    const [companies, setCompanies] = useState([])

    useQuery(FIND_MANY_COMPANY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ findManyCompany }) => {
            const companiesData = findManyCompany.map(object => ({
                value: object.id,
                label: object.name
            }))
            setCompanies(companiesData)
        }
    })

    const [createOneObjectMutation, { loading: createOneObjectLoading }] = useMutation(CREATE_ONE_OBJECT, {
        onCompleted: () => {
            form.resetFields()
            message.success('Обьект добавлен')
            navigate(-1)
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        }
    })

    const handleSubmit = async (values) => {
        const files = images.map(object => object.response.filename) || []

        await createOneObjectMutation({
            variables: {
                data: {
                    name: values.name,
                    description: values.description,
                    images: files,
                    location: values.location,
                    people: values.people,
                    peopleInfo: values.peopleInfo,
                    bed: values.bed,
                    bedInfo: values.bedInfo,
                    square: values.square,
                    comforts: values.comforts,
                    tags: values.tags,
                    videos: values.videos,
                    rules: values.rules,
                    booking: values.booking,
                    premium: values.premium,
                    publish: values.publish,
                    heat: values.heat,
                    place: values.place,
                    address: values.address,
                    company: {
                        connect: {
                            id: values.company
                        }
                    }
                }
            }
        })
    }

    const onPreview = async (file) => {
        const name = file.response.filename
        window.open(`${HOST_NAME}uploads/${name}`, '_blank')
    }

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setImages((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item label='Изображение' >
                    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                        <SortableContext items={images.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                            <Upload
                                multiple
                                action={HOST_NAME + 'upload'}
                                listType='picture'
                                fileList={images}
                                onChange={({ fileList }) => {
                                    setImages(fileList)
                                }}
                                onPreview={onPreview}
                                itemRender={(originNode, file) => (
                                    <DraggableUploadListItem originNode={originNode} file={file} />
                                )}
                            >
                                <Button icon={<UploadOutlined />}>+ Загрузить</Button>
                            </Upload>
                        </SortableContext>
                    </DndContext>
                </Form.Item>
                <Form.Item name='videos' label='Видео'>
                    <Select mode='tags' placeholder='Введите ссылку youtube' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='company' label='Компания'>
                    <Select placeholder='Выберите компанию' options={companies} />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='name' label='Название обьекта'>
                    <Input placeholder='Введите имя' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='description' label='Описание'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='booking' label='Бронирование и оплата'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='rules' label='Правила'>
                    <ReactQuill modules={modules} theme='snow' />
                </Form.Item>
                <Form.Item name='location' label='Локация'>
                    <Input placeholder='Введите url с 2gis' />
                </Form.Item>
                <Form.Item name='place' label='Расположение'>
                    <Select placeholder='Расположение' options={places} />
                </Form.Item>
                <Form.Item name='address' label='Адрес'>
                    <Input placeholder='Введите адрес' />
                </Form.Item>
                <Form.Item name='people' label='Количество гостей'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите количество гостей' />
                </Form.Item>
                <Form.Item name='peopleInfo' label='Описание о количество гостей'>
                    <Input placeholder='Введите описание о количество гостей' />
                </Form.Item>
                <Form.Item name='bed' label='Спальные места'>
                    <InputNumber style={{ width: '100%' }} placeholder='Введите количество спальных мест' />
                </Form.Item>
                <Form.Item name='bedInfo' label='Описание о спальных мест'>
                    <Input placeholder='Введите описание о спальных мест' />
                </Form.Item>
                <Form.Item name='square' label='Площадь'>
                    <Input placeholder='Введите площадь' />
                </Form.Item>
                <Form.Item name='heat' label='Отопление'>
                    <Input placeholder='Введите описание' />
                </Form.Item>
                <Form.Item name='tags' label='Тэги'>
                    <Select
                        mode='multiple'
                        placeholder='Тэги'
                        optionFilterProp='label'
                        options={tags}
                    />
                </Form.Item>
                <Form.Item name='comforts' label='Удобства'>
                    <Select
                        mode='tags'
                        placeholder='Удобства'
                        optionFilterProp='label'
                    />
                </Form.Item>
                <Form.Item name='premium' label='Интересно'>
                    <Switch />
                </Form.Item>
                <Form.Item name='publish' label='Опубликовано'>
                    <Switch defaultValue={true} />
                </Form.Item>
                <Button loading={createOneObjectLoading} type='primary' htmlType='submit'>
                    Добавить
                </Button>
            </Form>
        </>
    )
}

export default AddObject
