import { useState, useMemo } from 'react'
import {
    Table,
    Form,
    Input,
    Select,
    Tooltip
} from 'antd'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import Title from '../components/Title'

import { FIND_MANY_REQUEST, FIND_MANY_REQUEST_COUNT } from '../gqls'

const Filters = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
        width: 200px;
        margin-right: 15px;
    }
`

const limit = 10

const statuses = {
    '0': 'Ждет подтверждения',
    '1': 'Оплачен',
    '2': 'Завершен',
    '3': 'Отменен',
    '4': 'Отменен гостем',
}

const sorts = {
    createdAt: 'Новые',
    priceMin: 'По цене (По убыванию)',
    priceMax: 'По цене (По возрастанию)',
}

const sortsFilter = {
    createdAt: {
        createdAt: 'desc'
    },
    priceMin: {
        schedule: {
            price: 'desc'
        }
    },
    priceMax: {
        schedule: {
            price: 'asc'
        }
    },
}

const Requests = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState(null)
    const [orderBy, setOrderBy] = useState('createdAt')
    const variables = useMemo(() => ({
        where: {
            OR: [
                {
                    name: { contains: search, mode: 'insensitive' },
                },
                {
                    phone: { contains: search, mode: 'insensitive' },
                },
                {
                    object: {
                        is: {
                            name: { contains: search, mode: 'insensitive' },
                        }
                    }
                }
            ],
            status: status ? { equals: status } : undefined,
            object: {
                isNot: null
            },
            // schedule: {
            //     isNot: null
            // },
            user: {
                isNot: null
            }
        },
        orderBy: sortsFilter[orderBy],
    }), [search, status, orderBy])

    const { data: findManyRequestData, loading: findManyRequestLoading } = useQuery(FIND_MANY_REQUEST, {
        variables: {
            ...variables,
            take: limit * currentPage,
            skip: 0,
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyRequestCountData, loading: findManyRequestCountLoading } = useQuery(FIND_MANY_REQUEST_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const findManyRequest = findManyRequestData?.findManyRequest || []
    const findManyRequestCount = findManyRequestCountData?.findManyRequestCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    return (
        <>
            <Title
                text={`Список заявок (${findManyRequestCount})`}
            />
            <Filters>
                <Select
                    className='item'
                    allowClear
                    placeholder='Сортировка'
                    onSelect={(data) => {
                        setOrderBy(data)
                    }}
                    onClear={() => setOrderBy('createdAt')}
                >
                    {
                        Object.keys(sorts).map(key => (
                            <Select.Option
                                key={key}
                            >
                                {sorts[key]}
                            </Select.Option>
                        ))
                    }
                </Select>
                <Input
                    placeholder='Поиск'
                    allowClear
                    onChange={e => setSearch(e.target.value ? e.target.value : '')}
                    className='item'
                />
                <Select
                    className='item'
                    allowClear
                    placeholder='Статус'
                    onSelect={data => setStatus(data)}
                    onClear={() => setStatus(null)}
                >
                    {
                        Object.keys(statuses).map(key => (
                            <Select.Option
                                key={key}
                            >
                                {statuses[key]}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Filters>
            <Table
                loading={findManyRequestLoading || findManyRequestCountLoading}
                rowClassName={(record, index) => record.status == 0 ? 'table-row-new' : record.status == 1 ? 'table-row-payed' : 'table-row-cancel'}
                rowKey={(obj) => obj.id}
                dataSource={findManyRequest}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyRequestCount,
                    pageSize: 10
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Дата оформление',
                        dataIndex: 'createdAt',
                        key: 'createdAt',
                        render: (createdAt) => <span>{dayjs(createdAt).format('DD.MM.YYYY')}</span>
                    },
                    {
                        title: 'Дата и время брони',
                        dataIndex: 'reservedAt',
                        key: 'reservedAt',
                        render: (reservedAt, object) => <span>{dayjs(reservedAt).format('DD.MM.YYYY')} {object?.schedule?.startAt}-{object?.schedule?.endAt}</span>
                    },
                    {
                        title: 'Имя',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name) => <span>{name}</span>
                    },
                    {
                        title: 'Телефон',
                        dataIndex: 'phone',
                        key: 'phone',
                        render: (phone) => <span>{phone}</span>
                    },
                    {
                        title: 'Обьект',
                        dataIndex: 'object',
                        key: 'object',
                        render: (object) => <span>{object?.name}</span>
                    },
                    {
                        title: 'Цена',
                        dataIndex: 'price',
                        key: 'price',
                        render: (price, object) => <span>{price != null ? (object?.discount || price) : object?.schedule?.price}</span>
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status, object) => (status == 3 || status == 4) ? (<Tooltip placement="top" title={object?.cmntText}>
                            <span style={{ cursor: 'pointer' }}>{statuses[status]}</span>
                        </Tooltip>) : <span>{statuses[status]}</span>
                    },
                ]}

            />
        </>
    )
}

export default Requests