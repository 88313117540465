import React from 'react'

import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { Table } from 'antd'
import { FIND_MANY_REQUEST } from '../gqls'

const WeeklyStatistics = () => {
    const today = dayjs();

    const lastWeekMonday = today.startOf('week').subtract(1, 'week').add(1, 'day').startOf('day');
    const lastWeekSunday = lastWeekMonday.add(6, 'day').endOf('day');
    const { data: findManyRequestData, loading: findManyRequestLoading, error: findManyRequestError } = useQuery(FIND_MANY_REQUEST, {
        variables: {
            where: {
                createdAt: {
                    gte: lastWeekMonday.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
                    // lte: lastWeekSunday.format('YYYY-MM-DDTHH:mm:ss.sssZ'),
                }
            }
        },
        fetchPolicy: 'network-only',
    })

    if (findManyRequestLoading || findManyRequestError) return null
    const { findManyRequest } = findManyRequestData
    var countRequestPayedLastWeek = 0;
    var countRequestNoPayedLastWeek = 0;
    var countRequestCancelLastWeek = 0;
    var countRequestTestCancelLastWeek = 0;
    var sumLastWeek = 0;
    var countRequestLastWeek = 0;
    var countRequestPayedRealWeek = 0;
    var countRequestNoPayedRealWeek = 0;
    var countRequestCancelRealWeek = 0;
    var countRequestTestCancelRealWeek = 0;
    var sumRealWeek = 0;
    var countRequestRealWeek = 0;
    findManyRequest.forEach(request => {
        if (request.createdAt <= lastWeekSunday.format('YYYY-MM-DDTHH:mm:ss.sssZ')) {
            countRequestLastWeek += 1
            switch (request.status) {
                case '0':
                    countRequestNoPayedLastWeek += 1
                    break;
                case '1':
                    sumLastWeek += request.discount ? request.discount : request.price
                    countRequestPayedLastWeek += 1
                    break;
                case '3':
                case '4':
                    if (request.phone == '79644236888')
                        countRequestTestCancelLastWeek += 1
                    else
                        countRequestCancelLastWeek += 1
                    break;
                default:
                    break;
            }
        } else {
            countRequestRealWeek += 1
            switch (request.status) {
                case '0':
                    countRequestNoPayedRealWeek += 1
                    break;
                case '1':
                    sumRealWeek += request.discount ? request.discount : request.price
                    countRequestPayedRealWeek += 1
                    break;
                case '3':
                case '4':
                    if (request.phone == '79644236888')
                        countRequestTestCancelRealWeek += 1
                    else
                        countRequestCancelRealWeek += 1
                    break;
                default:
                    break;
            }
        }
    });
    countRequestLastWeek = countRequestLastWeek - countRequestTestCancelLastWeek
    const columns = [
        {
            title: '',
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: 'Количество',
            dataIndex: 'count',
            key: 'count',
        },
    ];
    var cancelTextLastWeek = [
        { text: 'Общее количество заявок', count: countRequestLastWeek },
        { text: 'Оплаченные', count: countRequestPayedLastWeek + ' (' + Math.floor(countRequestPayedLastWeek / countRequestLastWeek * 100) + '%)' },
        { text: 'Ждут подтверждения', count: countRequestNoPayedLastWeek + ' (' + Math.floor(countRequestNoPayedLastWeek / countRequestLastWeek * 100) + '%)' },
        { text: 'Отменены', count: countRequestCancelLastWeek + ' (' + Math.floor(countRequestCancelLastWeek / countRequestLastWeek * 100) + '%) (' + countRequestTestCancelLastWeek + ')' }]
    var cancelTextRealWeek = [
        { text: 'Общее количество заявок', count: countRequestRealWeek },
        { text: 'Оплаченные', count: countRequestPayedRealWeek + ' (' + Math.floor(countRequestPayedRealWeek / countRequestRealWeek * 100) + '%)' },
        { text: 'Ждут подтверждения', count: countRequestNoPayedRealWeek + ' (' + Math.floor(countRequestNoPayedRealWeek / countRequestRealWeek * 100) + '%)' },
        { text: 'Отменены', count: countRequestCancelRealWeek + ' (' + Math.floor(countRequestCancelRealWeek / countRequestRealWeek * 100) + '%) (' + countRequestTestCancelRealWeek + ')' }]
    return <>
        <h2>Эта неделя</h2>
        <h2>Дата с {lastWeekSunday.add(1, 'day').format('DD.MM.YYYY')} по {today.format('DD.MM.YYYY')}</h2>

        <Table
            dataSource={cancelTextRealWeek.filter((item) => item.text)}
            columns={columns}
            rowKey="text"
            pagination={false} // Отключаем пагинацию
        />
        <h2>Прошлая неделя</h2>
        <h2>Дата с {lastWeekMonday.format('DD.MM.YYYY')} по {lastWeekSunday.format('DD.MM.YYYY')}</h2>

        <Table
            dataSource={cancelTextLastWeek.filter((item) => item.text)}
            columns={columns}
            rowKey="text"
            pagination={false} // Отключаем пагинацию
        />
    </>;
}

export default WeeklyStatistics
