import { useState, useMemo } from 'react'
import {
    Table,
    Button,
    Form,
    Input,
} from 'antd'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Title from '../components/Title'

import { FIND_MANY_COMPANY_LIST, FIND_MANY_COMPANY_COUNT } from '../gqls'

const Filters = styled(Form)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .item {
        width: 200px;
        margin-right: 15px;
    }
`

const limit = 20


const Companies = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [search, setSearch] = useState('')

    const variables = useMemo(() => ({
        where: {
            OR: [
                {
                    name: { contains: search, mode: 'insensitive' },
                },
                {
                    phone: { contains: search, mode: 'insensitive' },
                },
                {
                    email: { contains: search, mode: 'insensitive' },
                }
            ]
        }
    }), [search])

    const { data: findManyCompanyData, loading: findManyCompanyLoading } = useQuery(FIND_MANY_COMPANY_LIST, {
        variables: {
            ...variables,
            take: limit * currentPage,
            skip: 0,
            orderBy: {
                createdAt: 'desc'
            },
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyCompanyCountData, loading: findManyCompanyCountLoading } = useQuery(FIND_MANY_COMPANY_COUNT, {
        variables,
        fetchPolicy: 'network-only'
    })

    const findManyCompany = findManyCompanyData?.findManyCompany || []
    const findManyCompanyCount = findManyCompanyCountData?.findManyCompanyCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    const getSum = (company) => {
        var sum = 0;
        company.objects.forEach(object => {
            object.requests.forEach(request => {
                if (request.status == '1')
                    sum += request.discount? request.discount : request.price
            })
        });
        return <span>{sum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}</span>
      };

    return (
        <>
            <Title
                text={`Список компаний (${findManyCompanyCount})`}
                action={
                    <Link to={'add'}>
                        <Button type='primary'>Добавить</Button>
                    </Link>
                }
            />
            <Filters>
                <Input
                    placeholder='Поиск'
                    allowClear
                    onChange={e => setSearch(e.target.value ? e.target.value : '')}
                    className='item'
                />
            </Filters>
            <Table
                loading={findManyCompanyLoading || findManyCompanyCountLoading}
                rowKey={(obj) => obj.id}
                dataSource={findManyCompany}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyCompanyCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Название',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name) => <span>{name}</span>
                    },
                    {
                        title: 'Телефон',
                        dataIndex: 'phone',
                        key: 'phone',
                        render: (phone) => <span>{phone}</span>
                    },
                    {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        render: (email) => <span>{email}</span>
                    },
                    {
                        title: 'Сумма',
                        dataIndex: 'email',
                        key: 'email',
                        render: (reservedAt, company) =>  <Link to={company.id+'/statistics'}>{getSum(company)}</Link>
                    },
                    
                ]}

            />
        </>
    )
}

export default Companies