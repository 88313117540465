import React, { useState } from 'react'

import styled from 'styled-components'
import { Form as AntForm, Button, message, Input, Popconfirm, InputNumber, Select, Switch, DatePicker } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import IMask from 'imask'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import { UPDATE_ONE_ADMIN_TG_BOT, FIND_UNIQUE_ADMIN_TG_BOT, DELETE_ONE_ADMIN_TG_BOT } from '../gqls'

const Form = styled(AntForm)`
    max-width: 600px;
`

const rules = {
    required: {
        required: true,
        message: 'Обязательное поле'
    }
}

const format = 'DD.MM.YYYY'

const CompanyAdminTgBot = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const { id } = useParams()

    useQuery(FIND_UNIQUE_ADMIN_TG_BOT, {
        variables: {
            where: {
                id
            }
        },
        onCompleted: ({ findUniqueAdminTgBot }) => {
            form.setFieldsValue({
                phone: findUniqueAdminTgBot.phone,
                code: findUniqueAdminTgBot.code,
                auth: findUniqueAdminTgBot.auth,
                status: findUniqueAdminTgBot.status,
            })
        }
    })

    const [updateOneAdminTgBotMutation, { loading: updateOneAdminTgBotLoading }] = useMutation(UPDATE_ONE_ADMIN_TG_BOT, {
        onCompleted: () => {
            message.success('Данные обновлены')
        },
        onError: e => {
            message.error('Что то пошло не так, повторите попытку позже')
        }
    })

    const [deleteOneAdminTgBotMutation] = useMutation(DELETE_ONE_ADMIN_TG_BOT, {
        onCompleted: () => {
            message.success('Админ удален')
            navigate(-1)
        },
        onError: (err) => {
            console.error(err)
            message.error('Не удалось удалить')
        },
    })

    const handleSubmit = async (values) => {
        await updateOneAdminTgBotMutation({
            variables: {
                where: {
                    id
                },
                data: {
                    phone: {
                        set: values.phone
                    },
                    auth: {
                        set: values.auth
                    },
                    status: {
                        set: values.status
                    }
                }
            }
        })
    }

    const onDelete = async () => {
        if (id) {
            await deleteOneAdminTgBotMutation({
                variables: {
                    where: {
                        id
                    }
                }
            })
        }
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout='vertical'>
                <Form.Item rules={[rules.required]} name='phone' label='Номер телефона'>
                    <Input placeholder='Введите номер телефона' />
                </Form.Item>
                <Form.Item rules={[rules.required]} name='code' label='Код авторизации' >
                    <InputNumber style={{ width: '100%' }} placeholder='Код авторизации' disabled />
                </Form.Item>
                <Form.Item name='auth' label='Авторизован'>
                    <Switch />
                </Form.Item>
                <Form.Item name='status' label='Статус'>
                    <Switch />
                </Form.Item>
                <Button loading={updateOneAdminTgBotLoading} type='primary' htmlType='submit'>
                    Сохранить
                </Button>
                <Popconfirm title='Удалить?' onConfirm={onDelete}>
                    <Button type='primary' danger style={{ marginLeft: 5 }}>
                        Удалить
                    </Button>
                </Popconfirm>
            </Form>
        </>
    )
}

export default CompanyAdminTgBot
